import React, { useState, useEffect, useRef } from "react";
import ModalAddOneExpense from "../components/expense/Modal-add-one-expense";
import ModalResetOneExpense from "../components/expense/Modal-reset-one-expense";
import ModalRegisterExpense from "../components/expense/Modal-register-expense";
import TableExpenses from "../components/expense/Table-expenses";
import PdfExpenses from "../components/expense/Pdf-expenses";
import "jspdf-autotable";
import "react-datepicker/dist/react-datepicker.css";

function Expenses() {
    /////////////////////////////////////////////////
    const apiUrl = process.env.REACT_APP_API_URL;
    //////////////////////////////////////////////////
    const [productos, setProductos] = useState([]);
    const [id, setId] = useState(null);
    const [tipoPaquete, setTtipoPaquete] = useState("");
    const [cantidad_paquetes, setCantidad_paquetes] = useState("");
    const [modalIsOpenAddOne, setModalIsOpenAddOne] = useState(false);
    const [modalIsOpenResetOne, setModalIsOpenResetOne] = useState(false);
    const [modalIsOpenRegister, setModalIsOpenRegister] = useState(false);
    const [message, setMessage] = useState("");
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);

    const ChilComponentRef = useRef();
    ///////////////////////////////////////////////////////////////
    const openModalAddOne = () => setModalIsOpenAddOne(true);
    const closeModalAddOne = () => setModalIsOpenAddOne(false);

    const openModalResetOne = () => setModalIsOpenResetOne(true);
    const closeModalResetOne = () => setModalIsOpenResetOne(false);

    const openModalRegister = () => setModalIsOpenRegister(true);
    const closeModalRegister = () => setModalIsOpenRegister(false);
    ////////////////////////////////////////////////////////////////////
    useEffect(() => {
        obtenerGastosIngredientes();
    }, []);
    //////////////////////////////////////////////

    ////////////////////////////////////////////////////OBTNER GASTOS DE INGREDIENTES
    const obtenerGastosIngredientes = async () => {
        try {
            const response = await fetch(`${apiUrl}/expenses/get-expenses.php`);
            if (!response.ok) {
                throw new Error("Error fetching data");
            }
            const data = await response.json();
            setProductos(data);
            console.log("Datos obtenidos:", data);
        } catch (error) {
            console.error("Error fetching data:", error);
            setProductos([]);
        }
    };
    const handleClickAddOne = (id) => {
        setId(id);
        openModalAddOne();
    };

    const handleClickResetOne = (id) => {
        setId(id);
        openModalResetOne();
    };
    ////////////////////////////////////////////////ACTUALIZAR CANTIDAD DE PAQUETES, UNIDADES Y TOTAL $
    const handleSaveOne = async () => {
        const data = { cantidad_paquetes: cantidad_paquetes };
        try {
            const response = await fetch(
                `${apiUrl}/expenses/put-expenses.php/${id}`,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(data),
                }
            );
            const updatedExpense = await response.json();
            setProductos((prevProductos) =>
                prevProductos.map((gasto) =>
                    gasto.id === updatedExpense.id ? updatedExpense : gasto
                )
            );
            closeModalAddOne();
            setCantidad_paquetes("");
            obtenerGastosIngredientes();
        } catch (error) {
            console.error("Error updating data:", error);
        }
    };
    ///////////////////////////////////////////////////////////////////RESETEAR POR REGISTRO EN GASTOS
    const handleSaveResetOne = async () => {
        const data = { stock_paquetes: 0 };
        try {
            const response = await fetch(
                `${apiUrl}/expenses/put-expense-zero.php/${id}`,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(data),
                }
            );
            const updatedExpense = await response.json();
            setProductos((prevProductos) =>
                prevProductos.map((gasto) =>
                    gasto.id === updatedExpense.id ? updatedExpense : gasto
                )
            );
            closeModalResetOne();
            obtenerGastosIngredientes();
        } catch (error) {
            console.error("Error updating data:", error);
        }
    };
    const handleSaveGastos = async () => {
        try {
            const date = new Date();

            // Obtener año, mes y día en la zona horaria local
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() devuelve un valor de 0-11, por lo que sumamos 1
            const day = String(date.getDate()).padStart(2, "0");

            const hours = date.getHours().toString().padStart(2, "0");
            const minutes = date.getMinutes().toString().padStart(2, "0");
            const seconds = date.getSeconds().toString().padStart(2, "0");

            // Formatear fecha y hora
            const dateString = `${year}-${month}-${day}`;
            const time = `${hours}:${minutes}:${seconds}`;
            // Actualizar los estados de fecha y hora
            setSelectedDate(dateString);
            setSelectedTime(time);
            console.log(
                `La fecha desde expense es: ${dateString} y la hora ${time}`
            );

            // Realiza la solicitud POST al endpoint en el backend
            const response = await fetch(
                `${apiUrl}/history-expenses/post-history-expenses.php`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        selectedDate: dateString,
                        selectedTime: time,
                    }),
                }
            );

            if (!response.ok) {
                throw new Error("Error en la respuesta del servidor");
            }

            // Genera el PDF si es necesario
            if (ChilComponentRef.current) {
                ChilComponentRef.current.generatePDF(dateString, time);
            }

            // Transferir gastos al inventario antes de resetear los gastos
            await transferGastosToInventory();

            // Resetear los gastos
            handleResetAllGastosClick();
        } catch (error) {
            console.error("Error guardando los datos:", error);
            alert(
                "Hubo un error al guardar los datos. Por favor, inténtalo de nuevo."
            );
        }
    };

    const transferGastosToInventory = async () => {
        try {
            const response = await fetch(
                `${apiUrl}/inventory/put-inventory_expenses.php`
            );

            if (!response.ok) {
                throw new Error("Error en la respuesta del servidor");
            }

            const data = await response.json();
            setMessage(data.message);
            console.log("Datos transferidos al inventario...");
            openModalRegister();
        } catch (error) {
            console.error("Error al obtener los datos:", error);
        }
        console.log(message);
    };

    const handleResetAllGastosClick = async () => {
        try {
            const response = await fetch(
                `${apiUrl}/expenses/put-expenses-full-zero.php`,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            if (!response.ok) {
                throw new Error("Error en la respuesta del servidor");
            }

            const data = await response.json();
            console.log(data);

            // Actualizar la lista de productos en el frontend
            const updatedGatos = productos.map((gasto) => {
                return {
                    ...gasto,
                    stock_paquetes: 0, // Restablecer a cero el stock_paquetes
                    stock_unidades: 0, // Restablecer a cero el stock_unidades
                    total: 0.0,
                };
            });
            setProductos(updatedGatos);
            console.log(data.message);
            console.log("datos reseteados..");
            obtenerGastosIngredientes();
        } catch (error) {
            console.error("Error al restablecer los valores:", error);
        }
    };

    /////////////////////7 validaciones para que solo acepte numeros
    return (
        <div className="expenses-container">
            <TableExpenses
                productos={productos}
                handleClickAddOne={handleClickAddOne}
                handleClickResetOne={handleClickResetOne}
                setTtipoPaquete={setTtipoPaquete}
                handleSave={handleSaveGastos}
                message={message}
            />
            <ModalRegisterExpense
                modalIsOpen={modalIsOpenRegister}
                closeModal={closeModalRegister}
                message={message}
            />

            <ModalAddOneExpense
                modalIsOpen={modalIsOpenAddOne}
                closeModal={closeModalAddOne}
                tipoPaquete={tipoPaquete}
                cantidad_paquetes={cantidad_paquetes}
                setCantidad_paquetes={setCantidad_paquetes}
                handleSave={handleSaveOne}
            />

            <ModalResetOneExpense
                modalIsOpen={modalIsOpenResetOne}
                closeModal={closeModalResetOne}
                tipoPaquete={tipoPaquete}
                handleReset={handleSaveResetOne}
            />

            <PdfExpenses ref={ChilComponentRef} data={productos} />
        </div>
    );
}

export default Expenses;
