import React, { useState, useEffect } from "react";
import Modal from "react-modal";

function Inventory() {
    /////////////////////////////////////////////////
    const apiUrl = process.env.REACT_APP_API_URL;
    //////////////////////////////////////////////////
    const [productos, setProductos] = useState([]);
    const [mensaje, setMensaje] = useState("");
    const [modalIsOpenReset, setModalIsOpenReset] = useState(false);

    useEffect(() => {
        obtenerIngredientesInv();
    }, []);

    //obtner ingredientes de inventario
    const obtenerIngredientesInv = async () => {
        try {
            const response = await fetch(
                `${apiUrl}/inventory/get-inventory.php`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        // Agrega otros headers si es necesario
                    },
                }
            );
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const data = await response.json();
            setProductos(data);
        } catch (error) {
            console.error(
                "There was a problem with the fetch operation:",
                error
            );
        }
    };
    //VACIAR INVENTARIO
    const handleResetAllInventoryClick = () => {
        fetch(`${apiUrl}/inventory/put-inventory-full-zero.php`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                // Actualizar la lista de productos en el frontend
                const updatedGatos = productos.map((gasto) => {
                    return {
                        ...gasto,
                        stock_paquetes: 0, // Restablecer a cero el stock_paquetes
                        stock_unidades: 0, // Restablecer a cero el stock_unidades
                        total: 0.0,
                    };
                });
                setProductos(updatedGatos);
                setMensaje(data.message);
                setModalIsOpenReset(false);
                obtenerIngredientesInv();
            })
            .catch((error) => {
                console.error("Error al restablecer los valores:", error);
                setMensaje("Hubo un error al restablecer los valores.");
            });
    };

    /////////////////////////////////////////////////////////////////////RETURN
    return (
        <div className=" burguer_table price-burguer">
            <h1 className="mb-4 ">Inventario de Ingredientes</h1>
            <div className="table-responsive burguer_table__content-table price-burguer__content-table">
                <table className="table table-striped table-bordered table-dark">
                    <thead className="thead-dark">
                        <tr>
                            <th class="long-name-inv">Producto</th>
                            <th class="short-name-inv">Producto</th>
                            <th class="long-name-inv">Stock paquetes</th>
                            <th class="short-name-inv">Paquetes</th>
                            <th class="long-name-inv">Stock unidades</th>
                            <th class="short-name-inv">Unidades</th>
                        </tr>
                    </thead>
                    <tbody>
                        {productos.map((producto) => (
                            <tr key={producto.id}>
                                <td>{producto.producto}</td>
                                <td>{producto.stock_paquetes}</td>
                                <td>{producto.stock_unidades}</td>
                            </tr>
                        ))}
                        {/* <tr>
                            <td colSpan={3}>
                                <button
                                    className="btn btn-danger"
                                    onClick={() => setModalIsOpenReset(true)}
                                >
                                    Vaciar Inventario
                                </button>
                            </td>
                        </tr> */}
                    </tbody>
                </table>
                <Modal
                    isOpen={modalIsOpenReset}
                    onRequestClose={() => setModalIsOpenReset(false)}
                    contentLabel="Mensaje"
                    style={{
                        content: {
                            top: "50%",
                            left: "50%",
                            right: "auto",
                            bottom: "auto",
                            marginRight: "-50%",
                            transform: "translate(-50%, -50%)",
                        },
                        overlay: {
                            backgroundColor: "rgba(0, 0, 0, 0.8)",
                        },
                    }}
                >
                    <div className="modal-edit">
                        <h2>{"vaciar inventario?"}</h2>
                        <div className="modal-edit__buttons">
                            <button
                                onClick={() => handleResetAllInventoryClick()}
                                class="btn btn-primary"
                                style={{ padding: "10px", marginRight: "4px" }}
                            >
                                Aseptar
                            </button>
                            <button
                                onClick={() => setModalIsOpenReset(false)}
                                class="btn btn-danger"
                                style={{ padding: "10px", marginRight: "4px" }}
                            >
                                Cancelar
                            </button>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    );
}

export default Inventory;
