import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";

function TableExpenseHistory({ productos, handleClickDelete, getDataPdf }) {
    /////////////////////////////////////////////////
    const apiUrl = process.env.REACT_APP_API_URL;
    //////////////////////////////////////////////////
    ///////////////////////////////////////////////////////
    const openPdfNewTab = async (id) => {
        try {
            const response = await fetch(
                `${apiUrl}/pdf-expense/get-pdf-expense.php?id=${id}`
            );
            if (!response.ok) {
                throw new Error("Error al recuperar el PDF");
            }
            const pdfBlob = await response.blob();
            const blobUrl = URL.createObjectURL(pdfBlob);
            window.open(blobUrl, "_blank");
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="burguer_table price-burguer">
            <h1 className="mb-4">Historial de Gastos</h1>
            <div className="table-responsive burguer_table__content-table price-burguer__content-table">
                <table className="table table-striped table-bordered table-dark">
                    <thead className="thead-dark">
                        <tr>
                            <th className="long-name-hg">Fecha</th>
                            <th className="short-name-hg">Fecha</th>
                            <th className="long-name-hg">Hora</th>
                            <th className="short-name-hg">Hora</th>
                            <th className="long-name-hg">Gasto</th>
                            <th className="short-name-hg">Gst</th>
                            <th></th>
                            <th className="th_pdf"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {productos.map((hg) => (
                            <tr key={hg.id}>
                                <td>{hg.fecha}</td>
                                <td>{hg.hora}</td>
                                <td>{Number(hg.total_gastos).toFixed(2)}</td>
                                <td>
                                    <div className="buttons-inv btn-delete">
                                        <div className="buttons-inv__button">
                                            <button
                                                className="btn btn-primary"
                                                onClick={() =>
                                                    openPdfNewTab(hg.id)
                                                }
                                            >
                                                <img
                                                    src="../pdf_img.png"
                                                    alt=""
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="buttons-inv btn-delete">
                                        <div className="buttons-inv__button">
                                            <button
                                                onClick={() => {
                                                    handleClickDelete(
                                                        hg.id,
                                                        hg.hora
                                                    );
                                                    getDataPdf(hg.id);
                                                }}
                                                className="btn btn-danger"
                                            >
                                                <img
                                                    src="../delete_img.png"
                                                    alt=""
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

TableExpenseHistory.propTypes = {
    productos: PropTypes.array.isRequired,
    handleClickDelete: PropTypes.func.isRequired,
};

export default TableExpenseHistory;
