import React, { useState, useEffect, useCallback } from "react";
import Modal from "react-modal";

Modal.setAppElement("#root");

function BurgerIngredientsPrices() {
    /////////////////////////////////////////////////
    const apiUrl = process.env.REACT_APP_API_URL;
    //////////////////////////////////////////////////
    const [productos, setProductos] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [id, setId] = useState(null);
    const [price_porcion, setPricePorcion] = useState("");
    const [price_paquete, setPricePaquete] = useState("");
    const [price_porcionDB, setPricePorcionDB] = useState("");
    const [price_paqueteDB, setPricePaqueteDB] = useState("");
    const [mensaje, setMensaje] = useState("");

    useEffect(() => {
        getIngredients();
    }, []);

    const getIngredients = async () => {
        try {
            const response = await fetch(
                `${apiUrl}/ingredients/get-ingredients.php`
            );
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const data = await response.json();
            setProductos(data);
        } catch (error) {
            console.error(
                "There was a problem with the fetch operation:",
                error
            );
        }
    };
    //al hacer click en editar se ejecuta esta funcion
    const handleEditClick = (id, price_paquete, price_porcion) => {
        setId(id);
        const paquete = parseFloat(price_paquete);
        const porcion = parseFloat(price_porcion);

        setPricePaquete(paquete.toFixed(2));
        setPricePorcion(porcion.toFixed(2));
        setPricePaqueteDB(paquete.toFixed(2));
        setPricePorcionDB(porcion.toFixed(2));

        setModalIsOpen(true);
    };
    //al dar click en guardar se ejecuta esta funcion
    const handleSaveClick = async () => {
        try {
            const response = await fetch(
                `${apiUrl}/ingredients/put-ingredients-prices.php/${id}`,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        precio_paquete: price_paquete,
                        precio_porcion: price_porcion,
                    }),
                }
            );

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const data = await response.json();

            setProductos(
                productos.map((producto) =>
                    producto.id === id
                        ? {
                              ...producto,
                              precio_paquete: price_paquete,
                              precio_porcion: price_porcion,
                          }
                        : producto
                )
            );

            setModalIsOpen(false);
            setId(null);
            setPricePaquete("");
            setPricePorcion("");
            setMensaje(data.message);
        } catch (error) {
            console.error("Error al actualizar los precios:", error);
        }
    };

    //para validar que solo se ingresen numeros y estos numero puedes ser
    const validarNumberFloat = (valor) => {
        const regex = /^\d*\.?\d{0,2}$/;
        return regex.test(valor);
    };

    // Función handleChange que utiliza ambas validaciones
    const handleChange = (event, setter) => {
        const valor = event.target.value;

        if (validarNumberFloat(valor) || valor === "") {
            setter(valor);
        } else {
        }
    };

    // Función que verifica si ambos inputs tienen valores
    const todosLosInputsLlenos = useCallback(() => {
        return price_paquete !== "" && price_porcion !== "";
    }, [price_paquete, price_porcion]);

    // Función que compara los valores de los inputs con los valores de la base de datos
    const compararValores = useCallback(() => {
        return (
            price_paquete === price_paqueteDB &&
            price_porcion === price_porcionDB
        );
    }, [price_paquete, price_porcion, price_paqueteDB, price_porcionDB]);

    //////////////////////////////////////////////////////////////////////////
    return (
        <div className=" burguer_table price-burguer">
            <h1 className="mb-4 ">Ingredientes</h1>
            <div className="table-responsive burguer_table__content-table price-burguer__content-table">
                <table className="table table-striped table-bordered table-dark">
                    <thead className="thead-dark">
                        <tr>
                            <th class="long-name-i">Producto</th>
                            <th class="short-name-i">Producto</th>
                            <th class="long-name-i">$ Paquetes</th>
                            <th class="short-name-i">Paq.</th>
                            <th class="long-name-i">$ Porción</th>
                            <th class="short-name-i">Porc.</th>
                            <th class="long-name-i">Unidades</th>
                            <th class="short-name-i">Unids</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {productos.map((ingrediente) => (
                            <tr key={ingrediente.id}>
                                <td>{ingrediente.producto}</td>
                                <td>{ingrediente.precio_paquete}</td>
                                <td>{ingrediente.precio_porcion}</td>
                                <td>{ingrediente.contenido_paquete}</td>

                                <td>
                                    <button
                                        onClick={() =>
                                            handleEditClick(
                                                ingrediente.id,
                                                ingrediente.precio_paquete,
                                                ingrediente.precio_porcion
                                            )
                                        }
                                        className="btn btn-success"
                                    >
                                        <img src="/edit_img.png" alt="" />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={() => setModalIsOpen(false)}
                    contentLabel="Editar Precio"
                    style={{
                        content: {
                            top: "50%",
                            left: "50%",
                            right: "auto",
                            bottom: "auto",
                            marginRight: "-50%",
                            transform: "translate(-50%, -50%)",
                        },
                        overlay: {
                            backgroundColor: "rgba(0, 0, 0, 0.8)", // Cambia esto al color que desees
                        },
                    }}
                >
                    <div className="modal-edit">
                        <div className="modal-edit__input">
                            <h2>Editar $ Paquete</h2>
                            <input
                                type="text"
                                value={price_paquete}
                                style={{ padding: "8px", marginRight: "4px" }}
                                onChange={(event) =>
                                    handleChange(event, setPricePaquete)
                                }
                            />
                            <h2>Editar $ Porcion</h2>
                            <input
                                type="text"
                                value={price_porcion}
                                style={{ padding: "8px", marginRight: "4px" }}
                                onChange={(event) =>
                                    handleChange(event, setPricePorcion)
                                }
                            />
                        </div>

                        <div className="modal-edit__buttons">
                            <button
                                onClick={handleSaveClick}
                                class="btn btn-primary"
                                style={{ padding: "10px", marginRight: "4px" }}
                                disabled={
                                    !todosLosInputsLlenos() || compararValores()
                                }
                            >
                                Guardar
                            </button>
                            <button
                                onClick={() => setModalIsOpen(false)}
                                class="btn btn-danger"
                                style={{ padding: "10px" }}
                            >
                                Cancelar
                            </button>
                        </div>
                    </div>
                </Modal>

                {mensaje && (
                    <Modal
                        isOpen={mensaje !== ""}
                        onRequestClose={() => setMensaje("")}
                        contentLabel="Mensaje"
                        style={{
                            content: {
                                top: "50%",
                                left: "50%",
                                right: "auto",
                                bottom: "auto",
                                marginRight: "-50%",
                                transform: "translate(-50%, -50%)",
                            },
                            overlay: {
                                backgroundColor: "rgba(0, 0, 0, 0.8)",
                            },
                        }}
                    >
                        <h2>{mensaje}</h2>
                        <button
                            onClick={() => setMensaje("")}
                            class="btn btn-danger"
                            style={{ padding: "6px" }}
                        >
                            Cerrar
                        </button>
                    </Modal>
                )}
            </div>
        </div>
    );
}

export default BurgerIngredientsPrices;
